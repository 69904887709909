export default class GoogleAnalytics {
    constructor(propertyId) {
        this.bootstrapGoogleAnalytics(propertyId);
    }

    navigateToPage(url) {
        this.trackPage(url);
    }

    bootstrapGoogleAnalytics(propertyId) {
        if (propertyId === "devfake") {
            window.ga = function() { console.log("GoogleAnalytics:", arguments); };
        } else {
            (function (i, s, o, g, r, a, m) {
                i["GoogleAnalyticsObject"] = r;
                (i[r] =
                    i[r] ||
                    function () {
                        (i[r].q = i[r].q || []).push(arguments);
                    }),
                    (i[r].l = 1 * new Date());
                (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
                a.async = 1;
                a.src = g;
                m.parentNode.insertBefore(a, m);
            })(
                window,
                document,
                "script",
                "https://www.google-analytics.com/analytics.js",
                "ga"
            );
        }

        ga("create", propertyId, "auto");
    };

    trackPage(url) {
        ga("set", "page", url);
        ga("send", "pageview");
    };
}
