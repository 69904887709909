import { Elm } from '../src/Main.elm'
import GoogleAnalytics from './google-analytics'
const Flow = require('@flowjs/flow.js');
import XLSX from 'xlsx';

let app = Elm.Main.init();

function fetchMetaData(field) {
    const element = document.querySelector(`meta[name=${field}]`);

    if (element) {
        return element.getAttribute("content");
    }
};

/*let ga4analytics = new GoogleAnalytics(fetchMetaData("ga4-tracking-id"))

app.ports.trackGoogleAnalytics.subscribe(handleTrackGoogleAnalytics);

function handleTrackGoogleAnalytics(payload) {
    ga4analytics[payload.action](payload.data);
};*/

app.ports.teardownSummernote.subscribe(teardownSummernote);
app.ports.setSummernoteContents.subscribe(setSummernoteContents);

app.ports.setupFlowJS.subscribe(setupFlowJS);
app.ports.teardownFlowJS.subscribe(teardownFlowJS);

app.ports.writeSheetJS.subscribe(writeSheetJS);

app.ports.reportBug.subscribe(reportBug);

app.ports.printPage.subscribe(printPage);

function summernoteInitialized(elem) {
    return elem.next('.note-editor').length !== 0
};

function setupSummernote(elem, elementId, fullscreenElementId, isFullscreenElement) {
    elem.summernote({
        lang: 'fi-FI',
        dialogsInBody: false,
        callbacks: {
            onChange: onSummernoteChange(elementId)
        },
        toolbar: [
            ['style', ['style']],
            ['font', ['bold', 'underline', 'superscript', 'subscript', 'clear']],
            ['fontname', ['fontname']],
            ['color', ['color']],
            ['para', ['ul', 'ol', 'paragraph']],
            ['table', ['table']],
            ['insert', ['link', 'picture', 'video']],
            ['view', ['customFullscreen', 'codeview', 'help']],
        ],
        buttons: {
            customFullscreen: summernoteFullscreenButton(fullscreenElementId, elementId),
        },
    });
    if (isFullscreenElement) {
        elem.summernote("fullscreen.toggle");
    }
};

function summernoteFullscreenButton(fullscreenElementId, elementId) {
    return function (context) {
        const summernote = $.summernote;
        const lang = summernote.lang[context.options.lang];

        const button = summernote.ui.button({
            className: 'btn-fullscreen',
            contents: summernote.ui.icon(summernote.options.icons.arrowsAlt),
            tooltip: lang.options.fullscreen,
            click: function () {
                const isFullscreen = context.invoke("fullscreen.isFullscreen");
                if (isFullscreen) {
                    const contents = $("#" + fullscreenElementId).summernote("code");
                    teardownSummernote({ elementId: fullscreenElementId });
                    $("#" + fullscreenElementId + "-div").hide();
                    setSummernoteContents({ elementId, contents });
                } else {
                    const contents = context.invoke("code");
                    $("#" + fullscreenElementId + "-div").show();
                    setupSummernote($("#" + fullscreenElementId), elementId, fullscreenElementId, true);
                    setSummernoteContents({ elementId: fullscreenElementId, contents });
                }
            },
        })

        return button.render();
    }
}


function teardownSummernote(args) {
    ifElementExists("#" + args.elementId, function (elem) {
        if (summernoteInitialized(elem)) {
            elem.summernote('destroy');
        }
    });
};

function setSummernoteContents(args) {
    whenElementExists("#" + args.elementId, function (elem) {
        if (!summernoteInitialized(elem)) {
            setupSummernote(elem, args.elementId, args.fullscreenElementId, false);
        }
        elem.summernote('code', args.contents);
    });
};

function onSummernoteChange(elementId) {
    return function (contents, $editable) {
        app.ports.onSummernoteChange.send({
            elementId: elementId,
            contents: contents,
        });
    };
};

let flowJS = {};

function setupFlowJS(args) {
    whenElementExists("#" + args.elementId, function (elem) {
        if (flowJS[args.elementId]) {
            return;
        }

        let flow = new Flow({
            target: args.uploadUrl,
            singleFile: true
        });

        flow.on('filesSubmitted', function (array, event) {
            app.ports.onFlowJSFileSubmitted.send({
                elementId: args.elementId,
                filename: array[0].name,
            });

            flow.upload();
        });

        flow.on('fileProgress', function (file, chunk) {
            app.ports.onFlowJSFileProgress.send({
                elementId: args.elementId,
                progress: Math.floor(file.progress(false) * 100)
            });
        });

        flow.on('fileSuccess', function (file, message) {
            app.ports.onFlowJSFileSuccess.send({
                elementId: args.elementId,
                uploadId: JSON.parse(message),
            });
        });

        flow.on('fileError', function (file, message) {
            app.ports.onFlowJSFileError.send({
                elementId: args.elementId,
            });;
        });

        flow.assignBrowse(elem[0], false, true);

        flowJS[args.elementId] = flow;
    });
};

function teardownFlowJS(args) {
    flowJS[args.elementId] = undefined;
};

function writeSheetJS({ fileName, columnNames, rows }) {
    const typedStringToValue = ({ valueType, value }) => {
        switch (valueType) {
            case "string":
                return value;

            case "int":
                return parseInt(value);

            case "bool":
                if (value === "true") {
                    return true;
                } else {
                    return false;
                }

            default:
                throw ("Unknown type: " + type);
        }
    };

    const aoa = [
        columnNames,
        ...rows.map((row) => row.map((val) => typedStringToValue(val))),
    ];

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(aoa);

    XLSX.utils.book_append_sheet(wb, ws, "Report");

    XLSX.writeFile(wb, fileName);
};

// Waits for an element matching `selector` to exist and then calls
// `f` with the jQuery object `$(selector)` as its argument.
function whenElementExists(selector, f) {
    function go(timeout) {
        setTimeout(function () {
            let elem = $(selector);
            if (elem.length > 0) {
                f(elem);
            } else {
                go(2 * (timeout + 1));
            }
        }, timeout);
    };
    go(0);
};

// If an element matching `selector` exists, calls `f` with the jQuery
// object `$(selector)` as its argument.
function ifElementExists(selector, f) {
    let elem = $(selector);
    if (elem.length > 0) {
        f(elem);
    }
};

function reportBug(args) {
    // TODO: rollbar
    console.log(args.message);
};

function printPage(args) {
    window.print();
}

app.ports.copyLawLinkToClipboardRequestPort.subscribe(({ path }) =>
    navigator.clipboard.writeText(`${window.location.origin}${path}`).then(
        () => app.ports.copyLawLinkToClipboardResponsePort.send({ succeeded: true }),
        () => app.ports.copyLawLinkToClipboardResponsePort.send({ succeeded: false })
    )
);
